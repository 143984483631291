.account-detail {
  .header h2 {
    font-size: 38px;
    padding: 40px 0;
    margin-bottom: 40px;
  }

  button {
    background: #2F80ED;
    border: 1px solid #0152BF;
    box-sizing: border-box;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    color: white;

    &:hover {
      background: #2F80ED;
      opacity: .8;
    }
  }

  .user-section {
    margin-bottom: 100px;

    .user-section-title {
      font-size: 24px;
      margin-bottom: 35px;
      font-weight: 600;
      color: #393939;
    }

    .user-section-row {
      display: flex;

      .user-input {
        margin-right: 20px;
        margin-bottom: 30px;

        label {
          display: block;
          color: #999999;
          margin-bottom: 15px;
        }

        input {
          appearance: none;
          outline: none;
          width: 350px;
          height: 42px;
          background: #FFFFFF;
          border: 1px solid #C6C6C6;
          box-sizing: border-box;
          box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.12);
          border-radius: 2px;
          padding: 9px 1em;
          font-size: 16px;

          &:disabled {
            cursor: not-allowed;
          }

          &:focus {
            border: 1px solid #6897c6;
          }
        }
      }
    }
  }

  .permissions {
    display: flex;

    .organizations {
      flex: 2;

      .organization {
        margin-bottom: 63px;

        h2 {
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 20px;
        }

        .locations {
          background: #FAFAFA;

          .location {
            display: flex;
            height: 67px;
            justify-content: center;
            align-items: center;
            border: 1px solid #EAEAEA;
            border-bottom: 0;
            padding: 15px 25px;

            &:first-child {
              border-radius: 4px 4px 0 0;
            }

            &:last-child {
              border-radius: 0 0 4px 4px;
              border-bottom: 1px solid #EAEAEA;
            }

            .name {
              font-size: 18px;
              color: #555555;
            }

            .permission {
              margin-left: auto;
            }
          }
        }
      }
    }

    .permissions-info {
      padding: 50px;
      margin-left: 50px;
      flex: 1;
      background: #F0F0F0;

      h2 {
        font-weight: bold;
        letter-spacing: .25em;
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 20px;
      }

      ul {
        padding: 0;

        li {
          margin-bottom: 20px;
          font-size: 14px;
          line-height: 19px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
