@positive-button-color: #3cb670;
@negative-button-color: #f56565;

.feedback-card {
  background-color: #fff;
  border: 1px solid #d1d1d1;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin-bottom: 24px;
  overflow: hidden;

  section {
    padding-left: 30px;
    padding-right: 15px;
  }

  .feedback-top {
    padding-top: 14px;
    padding-bottom: 30px;

    .feedback-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .contact-name {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: -0.02em;
      margin-top: 6px;
      margin-bottom: 8px;
    }

    .providers,
    .location {
      font-weight: 600;
      color: #626262;
      em {
        font-style: italic;
        font-weight: 400;
      }
    }

    .feedback-review {
      max-width: 800px;
      padding-right: 18px;
      font-size: 18px;
      line-height: 25px;
      margin-top: 15px;
      color: #626262;
    }

    .feedback-response {
      @background-color: #effaff;
      @border-color: #b5e2f6;
      background-color: @background-color;
      border: 1px solid @border-color;
      border-radius: 2px;
      padding: 15px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 20px;
      position: relative;
      font-size: 17px;
      line-height: 23px;
      color: #626262;

      &::before {
        .triangle(15px, @background-color, @border-color);
      }

      .response-text {
        // subtract left padding and border
        max-width: 800px - 16px;
      }

      .response-meta {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
      }
      .response-name {
        font-weight: 600;
        margin-top: 5px;
        padding-bottom: 5px;
      }

      .response-date {
        color: #75acc5;
        font-size: 13px;
        line-height: 18px;
      }
    }

    .ratings {
      display: flex;
      flex-direction: row;
      .rating + .rating {
        margin-left: 24px;
      }
      .star-rating {
        margin-top: 6px;
      }
    }
  }

  .feedback-next-action {
    border-top: 1px solid;
    padding-top: 8px;
    padding-bottom: 9px;
    font-size: 14px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    .description {
      margin-right: 30px;
      max-width: 800px;
    }

    .button.rounded {
      margin-right: -4px;
    }

    &.negative {
      color: #d83434;
      background-color: #ffd1d1;
      border-top-color: #ffa2a2;
      .button {
        svg {
          stroke-width: 2.5;
        }
        background-color: @negative-button-color;
        &:hover {
          background-color: darken(@negative-button-color, 5%);
        }
      }
    }

    &.positive {
      color: #0e4e29;
      background-color: #d9f2e4;
      border-top-color: #6fdc9d;
      .button {
        svg {
          stroke-width: 2.5;
        }
        background-color: @positive-button-color;
        &:hover {
          background-color: darken(@positive-button-color, 10%);
        }
      }
    }

    &.pending {
      color: #a58539;
      background-color: #fff5d7;
      border-top-color: #f9d780;
    }

    &.can-respond {
      flex-direction: column;
      align-items: baseline;

      .description {
        margin: 0;
      }

      .action {
        width: 100%;

        textarea {
          margin: 8px 0;
        }

        button {
          color: #fff;
        }
      }
    }
  }

  .feedback-footer {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f4f4f4;
    border-top: 1px solid #d1d1d1;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    .contact-info {
      display: flex;
      flex-direction: row;

      > * + * {
        margin-left: 20px;
      }
    }

    .feedback-date {
      font-size: 13px;
      color: #626262;
    }

    .review-outdated, .review-deleted{
      color: #666;
    }
  }
}

// confirmation modal for request review
// make primary button color match the next action button
.modal.confirm-request-review {
  > .actions button.primary {
    background-color: @negative-button-color;
    &:hover {
      background-color: darken(@negative-button-color, 5%);
    }
  }
}

// Less mixin for creating the triangle on top of the response bubble
.triangle(@size, @background-color, @border-color) {
  @width: sqrt(@size * @size + @size * @size);
  display: block;
  content: "";
  width: @size;
  height: @size;
  position: absolute;
  top: 0;
  left: 30px;
  background-color: @background-color;
  border-width: 1px;
  border-style: solid;
  border-color: @border-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  transform: translateY(0 - @width / 2) rotate(45deg);
  transform-origin: top left;
}
