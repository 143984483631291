.metrics-page-section {
  &:not(:empty) + &:not(:empty) {
    margin-top: 60px;
    padding-top: 50px;
    position: relative;

    &::before {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      // Dashed border with custom spacing
      // https://stackoverflow.com/a/18064496/879573
      background-image: linear-gradient(
        to right,
        #d5d5d5 70%,
        rgba(255, 255, 255, 0) 0%
      );
      background-size: 25px 1px;
      background-repeat: repeat-x;
    }
  }
}
