.thumb-badge {
  display: inline-block;
  border-radius: 3px;
  border: 1px solid;
  text-align: center;
  padding: 0.3em;
  padding-bottom: 0;

  &.thumb-down {
    color: #E06464;
    border-color: #EB907D;
    background-color: #FFD1D1;
  }

  &.thumb-up {
    color: #0E4E29;
    border-color: #27DB73;
    background-color: #93F2BB;
  }

}
