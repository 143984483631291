@import (reference) "~/variables.less";

.scratchpad {
  margin-top: 40px;

  h1 {
    font-size: 24px;
  }

  .spread-row {
    display: flex;
    flex-direction: row;
    .spread-row-left {
      margin-right: 20px;
    }
  }

  .scratchpad-tabs {
    .grid {
      display: flex;
      flex-direction: row;
    }

    .tabular.menu {
      padding-top: 20px;
      padding-bottom: 200px;
      border-right: 1px solid #ccc;

      a {
        cursor: pointer;
        display: block;
        color: black;
        font-size: 13px;
        padding: 10px;
        padding-left: 20px;
        padding-right: 40px;
        border: 1px solid transparent;
        margin-right: -1px;
        white-space: nowrap;
        text-decoration: none;

        &.active {
          border-color: #ccc;
          border-right-color: white;
          font-weight: 700;
          pointer-events: none;
        }
      }
    }

    .tab {
      padding-left: 40px;
      padding-top: 20px;
    }

  }

  .dropdowns-tab {
    .dropdown {
      max-width: 150px;
      .menu {
        max-width: 175px;
      }
    }
  }

  .nps-badge + .nps-badge {
    margin-left: 5px;
  }
  .thumb-badge + .thumb-badge {
    margin-left: 5px;
  }

  .icon-buttons-example {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    > div + div {
      margin-left: 10px;
    }
  }

  .button-size-example {
    button + button {
      margin-left: 10px;
    }
    margin-bottom: 20px;
  }

  .example {
    margin-bottom: 2em;
  }

  .sans-example {
    .font-sans;
    font-size: 24px;
  }
  .mono-example {
    .font-mono;
    font-size: 24px;
  }
}
