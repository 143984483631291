.footer-block {

  font-size: 14px;
  color: #999;
  padding-top: 80px;
  padding-bottom: 40px;

  footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footer-navigation {
    > * + * {
      margin-left: 30px;
    }
    a {
      color: #666;
    }
  }

  .logo svg {
    width: 40px;
  }
}
