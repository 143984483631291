@import (reference) "~/variables.less";

.block.navigation {
  border-bottom: 1px solid #eee;
  padding-top: 30px;
  padding-bottom: 30px;

  .logo-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 55px; // ensure consistent height with or without links rendered
  }

  .logo {
    color: #204975;
    line-height: 1;
    svg {
      transform: translateX(-14px);
      width: 170px;
    }
  }

  .links {
    @media @not-tablet {
      // cheat signout link padding into right margin
      margin-right: -15px;

      .main-navigation {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        line-height: 1;
      }

      a {
        padding: 10px 15px;
        font-size: 24px;
        display: block;
        color: #333;
        white-space: nowrap;
        letter-spacing: -0.02em;
        text-decoration: none;

        svg {
          width: 22px;
          margin-right: 8px;
          vertical-align: bottom;
          margin-bottom: 5px;
        }

        .text {
          display: inline-block;
          padding: 4px 0;
          border-bottom: 2px solid transparent;
        }

        &:not(.current):hover {
          cursor: pointer;
          .text {
            border-bottom-color: currentColor;
          }
        }

        &.current {
          color: #000;
          pointer-events: none;
          .text {
            border-bottom-color: currentColor;
          }
        }

        &.account, &.sign-out {
          font-size: 17px;
          color: #a3a3a3;
          svg {
            width: 18px;
            transform: translateY(2px);
          }
        }
      }

      a + a {
        margin-left: 5px;
      }
    }

    @media @tablet {
      .dropdown {
        border: none;
        box-shadow: none;
        padding: 8px;

        &:after {
          display: none;
        }

        > .text {
          display: none;
        }

        .menu {
          border-top-left-radius: 3px;
          padding-top: 8px;
          left: auto;
          right: 0;
          top: -5px;
          min-width: 200px;
        }
      }
    }

  }

}
