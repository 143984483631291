.ui.form {
  position: relative;
  max-width: 100%;
}

.ui.form textarea {
  width: 100%;
  vertical-align: top;
  margin: 0em;
  padding: 9px 1em;
  font-size: 14px;
  border: 1px solid #cacaca;
  outline: none;
  border-radius: 2px;
  line-height: 19px;
  resize: vertical;
}
