@broadly-blue: #094a79;

// all buttons
.button {
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  margin: 0;
  outline: none;
  font-family: inherit;

  & + .button {
    margin-left: 5px;
  }

  // disabled state
  &[disabled] {
    opacity: 0.6;
    cursor: default;
    pointer-events: none;
  }

  // loading state
  &.loading {
    position: relative;
    text-shadow: none !important;
    color: transparent !important;
    transition: all 0s linear, opacity 0.1s ease;

    @spinner-size: 1em;
    &::before {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      margin: -(@spinner-size / 2) 0em 0em -(@spinner-size / 2);
      width: @spinner-size;
      height: @spinner-size;
      border-radius: 50%;
      border: 0.2em solid fade(black, 20%);
    }

    &::after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      margin: -(@spinner-size / 2) 0em 0em -(@spinner-size / 2);
      width: @spinner-size;
      height: @spinner-size;
      animation: button-spinner 0.6s linear;
      animation-iteration-count: infinite;
      border-radius: 50%;
      border-color: fade(white, 90%) transparent transparent;
      border-style: solid;
      border-width: 0.2em;
      box-shadow: 0px 0px 0px 1px transparent;
    }
  }
}

@keyframes button-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// regular buttons - one size only
.button {
  border: 1px solid fade(black, 20%);
  color: #666;
  background-color: #f8f8f8;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  padding: 6px 0.8em;

  & + .button {
    margin-left: 10px;
  }

  &:hover {
    background-color: #f3f3f3;
  }

  &:active {
    box-shadow: none;
    transform: translateY(1px);
  }

  &[disabled] {
    box-shadow: none;
    transform: translateY(1px);
  }

  &.primary {
    // broadly blue, but lighter
    background-color: lighten(@broadly-blue, 10%);
    color: white;
    &:hover {
      background-color: @broadly-blue;
    }
  }

}

.button:not(.rounded) {

  /*
  buttons with icons

  <Button>
    <SVG/>
    <span className="divider"/>
    Button Text
  </Button>

  or

  <Button>
    Button Text
    <span className="divider"/>
    <SVG/>
  </Button>
  */

  .divider {
    left: 100px;
    top: 100px;
    display: inline-block;
    content: "";
    height: 1.3em;
    width: 0px;
    margin-top: -0.5em;
    margin-left: 0.7em;
    margin-right: 0.1em;
    border-left: 1px solid fade(black, 10%);
    border-right: 1px solid fade(white, 30%);
    transform: translateY(4px);
  }

  svg {
    height: 1.2em;
    transform: translateY(2.5px);
    margin-top: -0.5em;

    &:first-child {
      margin-left: -0.5em;
      & + .divider {
        margin-left: 0.15em;
        margin-right: 0.5em;
      }
    }

    &:last-child {
      margin-right: -0.5em;
    }
  }

}


// groups of buttons - one size only
.buttons {
  @rest-button-color: #ffffff;
  @rest-text-color: #627d98;
  @active-text-color: #2186eb;
  @active-button-color: #dbecfc;
  @hover-button-color: fade(@active-button-color, 25%);
  @group-border-color: #b2cae2;

  display: inline-flex;
  border: 1px solid @group-border-color;
  padding: 1px;
  border-radius: 2px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.12);

  .button {
    border: none;
    border-radius: 1px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    padding: 8px 1.5em;
    box-shadow: none;

    color: @rest-text-color;
    background-color: @rest-button-color;
    &:hover {
      background-color: @hover-button-color;
    }

    // selected button uses .active class (different from :active which is the
    // button state during mousedown)
    &.active {
      pointer-events: none;
      color: @active-text-color;
      background-color: @active-button-color;
      border-radius: 0;
      &:first-child {
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
      }
      &:last-child {
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
      }
    }
  }

  // show vertical divider between buttons
  .button + .button {
    position: relative;
    margin-left: 0px;
    &::before {
      display: block;
      content: "";
      position: absolute;
      width: 1px;
      top: 8px;
      bottom: 8px;
      left: 0px;
      transform: translateX(-0.5px);
      background-color: @group-border-color;
      box-shadow: 0 0 0 1px white;
    }
  }
}

// round buttons - one size only
.button.rounded {
  display: inline-block;
  outline: none;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  letter-spacing: -0.02em;
  color: #ffffff;
  background-color: #999;
  box-shadow: none;
  margin: 0;
  border: 1px solid fade(black, 30%);
  border-radius: 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  padding: 0px 0.9em;
  white-space: nowrap;

  & + .button {
    margin-left: 5px;
  }

  svg {
    height: 1em;
    transform-origin: top left;
    transform: translateY(1.5px);
    margin-left: -0.5em;
  }

}
