.date-range-selector {
  // copied these colors from button.less
  // might consolidate some base colors into variables.less, later
  @dark-blue: #2186eb;
  @light-blue: #dbecfc;

  @outer-border-color: #b2cae2;
  @rest-border-color: #e4e7e7;
  @rest-cell-color: #ffffff;
  @rest-text-color: #627d98;
  @hover-cell-color: fade(@light-blue, 25%);
  @blocked-cell-color: #f8f8f8;
  @blocked-text-color: #999;
  // these borders overlap, so using ligthen instead fade so alpha is 100%
  @active-border-color: lighten(@dark-blue, 30%);
  @active-cell-color: fade(@light-blue, 40%);
  @active-text-color: @dark-blue;
  @input-focus-color: @dark-blue;

  // outermost wrapper on date fields
  .DateRangePickerInput__withBorder {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.12);
    border-color: @outer-border-color;
    white-space: nowrap;
  }

  // wrapper on each date field
  .DateInput {
    width: 120px;
    .DateInput_input {
      font-weight: 600;
      color: #666;
      font-size: 14px;
      text-align: center;
      padding: 10px;
      padding-bottom: 8px; // subtract 2 for bottom border
      &:hover {
        background-color: @hover-cell-color;
      }
    }
    .DateInput_input__focused {
      border-bottom-color: @input-focus-color;
      background-color: @active-cell-color;
      color: @active-text-color;
    }
    &:first-child .DateInput_input__focused {
      border-bottom-left-radius: 1px;
    }
    &:last-child .DateInput_input__focused {
      border-bottom-right-radius: 1px;
    }
  }

  // arrow between date fields
  .DateRangePickerInput_arrow {
    margin-left: 5px;
    margin-right: 5px;
    svg {
      width: 20px;
    }
  }

  // triangle below focused field
  .DateInput_fang {
    // center it below the field
    left: 50%;
    transform: translateX(-50%) translateY(1px);
    .DateInput_fangStroke {
      stroke: @outer-border-color;
    }
  }

  // outer positional container for calendar popup
  .DateRangePicker_picker__directionLeft {
    // align calendar popup with right edge of input fields
    left: auto !important;
    right: 0;
    background-color: transparent;
  }
  // calendar popup
  .DayPicker__withBorder {
    border-radius: 3px;
    border: 1px solid @outer-border-color;
    box-shadow: 2px 2px 4px fade(black, 20%);
  }
  // horizontal scrolling list of months
  .CalendarMonthGrid {
    // default is white which covers our outer border
    background-color: transparent;
  }

  // cells for unselected, unblocked days
  .CalendarDay__default {
    color: @rest-text-color;
    background-color: @rest-cell-color;
    border-color: @rest-border-color;
    &:hover {
      background-color: @hover-cell-color;
    }
  }
  // cell for blocked day
  .CalendarDay__blocked_out_of_range {
    background-color: @blocked-cell-color;
    color: @blocked-text-color;
    pointer-events: none;
  }
  // cell for start date or end date
  .CalendarDay__selected {
    background-color: @active-cell-color;
    color: @active-text-color;
    border-color: @active-border-color;
  }
  // cell for any date between start and end
  .CalendarDay__selected_span {
    background-color: @active-cell-color;
    color: @active-text-color;
    border-color: @active-border-color;
  }

  // shortcut time period buttons. overriding some base .buttons styles so these
  // buttons match the design of calendar cells
  .date-range-buttons {
    padding: 0 22px;
    padding-bottom: 20px;

    .buttons {
      box-shadow: none;
      border: none;
      border-radius: 0;
      padding: 0;
      .button {
        padding: 5px 10px;
        border: 1px solid @rest-border-color;
        font-size: 13px;
        font-weight: 400;
        &.active {
          background-color: @active-cell-color;
          border-color: @active-border-color;
          z-index: 1;
        }
      }
      .button + .button {
        margin-left: -1px;
        &:before {
          display: none;
        }
      }
    }
  }

  // help button
  .DayPickerKeyboardShortcuts_show {
    display: none;
  }

}
