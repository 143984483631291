body.dimmable.dimmed {
  overflow: hidden;
}

.page.dimmer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px) saturate(50%);
  padding: 20px;
  padding-bottom: 200px; // bias the centering upward a bit
}

.modal {
  background-color: white;
  border: 1px solid fade(black, 50%);
  box-shadow: 2px 2px 10px fade(black, 20%);
  border-radius: 4px;
  overflow: hidden;
  width: 500px;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;

  > .header {
    font-size: 19px;
    padding: 20px 30px;
    border-bottom: 1px solid rgba(34,36,38,.15);
    font-weight: 600;
  }

  > .content {
    padding: 30px;
    flex-grow: 1;
    font-size: 18px;
    font-weight: 500;
  }

  > .actions {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px;
    background-color: tint(black, 95%);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
