.message.notification {
  position: fixed;
  z-index: 1000;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);

  padding: 15px 24px;
  border: 1px solid fade(black, 20%);
  border-radius: 3px;
  box-shadow: 2px 2px 4px fade(black, 5%);

  &.error {
    background-color: tint(red, 90%);
  }
  &.success {
    background-color: hsl(120, 60%, 95%);
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  i.close.icon {
    border-left: 1px solid fade(black, 20%);
    order: 1;
    line-height: 2;
    font-size: 12px;
    margin-left: 24px;
    padding-left: 20px;
    opacity: 0.7;
    cursor: pointer;
    &:before {
      display: block;
      content: "close";
      font-style: normal;
    }
  }

}
