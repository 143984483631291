@import (reference) "~/variables.less";

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0 @block-padding;

  > .block-content {
    width: 100%;
    max-width: @block-content-width;
  }

  > .wide-block-content {
    width: 100%;
  }

}
