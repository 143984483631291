.feedback-page {

  .facets {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;

    .brand-location-selectors {
      display: flex;
      flex-direction: row;
    }

    .brand-selector {
      width: 220px;
      margin-right: 10px;
    }
    .location-selector {
      width: 220px;
      margin-right: 40px;
    }

    .dropdown {
      line-height: 26px;
    }

    .date-download {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .download-excel-button {
      margin-left: 20px;
    }
  }

  .feedback-content {
    background-color: #fbfbfb;
    border-top: 1px solid #eee;
    padding-top: 25px;
    // ensure last item can scroll to near center of window
    // using padding because we want the pseudo-element below to position at bottom
    padding-bottom: 30vh;

    // use pseudo-element to graduate back to white before we reach the footer
    position: relative;
    &::after {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100vw;
      height: 100px;
      background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 90%);
    }

    button.loadMore {
      margin: auto;
      display: block;
      padding: 20px 50px;
    }
  }

  .filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .filter-header {
      font-size: 13px;
      font-weight: 600;
      color: #808080;
      text-transform: uppercase;
      letter-spacing: 0.2em;
    }

    .filter-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .total-count {
        margin-left: 5px;
        min-width: 38px;
        background: #e7eef4;
        color: #627d98;
        border-radius: 20px;
        padding: 0px 3px;
        font-size: 13px;
        font-weight: normal;

        &.loading {
          color: #928c8c;
        }
      }

      .sentiment-filter,
      .visibility-filter,
      .response-filter {
        margin-right: 20px;

        button {
          display: flex;
        }

        button.active {
          pointer-events: auto;

          .total-count {
            background: #f1f8ff;
            color: #2186eb;
          }
        }
      }
    }

    .team-member-selector {
      width: 250px;
    }

    .search-filter {
      flex: 1;

      input {
        @hover-border-color: #b2cae2;
        @rest-border-color: desaturate(@hover-border-color, 50%);
        @focus-border-color: darken(@hover-border-color, 20%);

        appearance: none;
        outline: none;
        border: 1px solid @rest-border-color;
        border-radius: 2px;
        line-height: 19px;
        padding: 9px 1em;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1;
        width: 100%;

        &:hover {
          border-color: @hover-border-color;
        }
        &:focus {
          border-color: @focus-border-color;
        }
      }
    }
  }

  .loading-shimmer {
    margin-top: 60px;
  }

  .date-group-header {
    color: #7c7c7c;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.555em;
    text-align: center;
    text-transform: uppercase;
    margin-top: 60px;
    margin-bottom: 40px;
    position: relative;
    &:before {
      width: 5em;
      display: inline-block;
      content: "";
      border-top: 1px solid #999;
      margin-right: 1.5em;
      position: relative;
      top: -5px;
    }
    &:after {
      width: 5em;
      display: inline-block;
      content: "";
      border-top: 1px solid #999;
      margin-left: 1.5em;
      position: relative;
      top: -5px;
    }
  }

  .feedback-columns {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .feedback-list-column {
      flex-grow: 1;
    }

    .filters-column {
      position: relative;

      &:before {
        position: absolute;
        display: block;
        content: "";
        width: 1px;
        height: 80%;
        background-color: fade(black, 5%);
        left: 24px;
        top: 120px;
      }
    }

    .team-filter {
      width: 250px;
      margin-left: 48px;
      padding-top: 88px; // should match overall height of date-group-header in feedback list

      .filter-header {
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        color: #808080;
        text-align: center;
        margin-bottom: 20px;
        padding-right: 1em;

        svg {
          margin-right: 10px;
          width: 1.2em;
          height: 1.2em;
          vertical-align: baseline;
          transform: translateY(1.5px);
        }
      }
    }
  }

}
