@import (reference) "~/variables.less";

.visualize-metrics {
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  width: 100%;

  .metrics {
    background: #fcfcfc;
    border-radius: 3px 0px 0px 3px;
    width: 130px;
    border-right: 1px solid #ececec;
    display: flex;
    flex-direction: column;
    padding: 5px;

    .title {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.25em;
      color: #626262;
      margin: 20px 0;
      text-transform: uppercase;
    }

    .delta {
      font-size: 20px;
    }

    .current-wrap {
      align-items: center;
    }

    .current {
      .font-mono;
      font-size: 36px;
      letter-spacing: -0.02em;
      text-align: right;
    }

    .meta {
      font-size: 13px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #aeaeae;
      margin: 20px 0;
    }
  }

  .compare-metrics {
    background: #fcfcfc;
    border-radius: 3px 0px 0px 3px;
    width: 130px;
    border-right: 1px solid #ececec;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .part {
      display: flex;
      flex-direction: column;
      padding: 5px;
      border-bottom: 1px solid #ececec;
      flex-grow: 2;

      &:last-child {
        border-bottom: none;
      }
      .delta {
        font-size: 16px;
      }

      .title {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #626262;
        text-align: left;
        display: inline-block;
        padding: 0 0 2px 0;
        margin: 4px 4px 4px 7px;
        border-bottom: 3px solid #000;
        align-self: flex-start;
      }

      .current-wrap {
        align-items: flex-start;
        justify-content: flex-end;
        margin: 0 7px;
      }

      .current {
        .font-mono;
        font-size: 30px;
        letter-spacing: -0.02em;
        line-height: 1.4;
        text-align: right;
        margin: 0;
      }
    }
  }

  .metrics,
  .compare-metrics {
    .current-wrap {
      flex-grow: 2;
      display: flex;
      justify-content: center;
    }

    .delta {
      .font-mono;
      font-size: 20px;
      letter-spacing: -0.02em;
      text-align: right;
      display: block;
      line-height: 0.7;
      color: #999;

      &.good {
        color: #3dc681;
      }
      &.bad {
        color: #d92b30;
      }
    }
  }

  .trends {
    width: calc(100% - 150px);
  }

  .truncated-y-axis-marker {
    // move marker to bottom left of the chart
    // this transform has to be done separately from the one below
    transform: translateY(100%);

    .marker-group {
      // rotate the marker and shift it upward and right
      transform: translateX(60px) translateY(-68px) rotate(60deg);
    }
    rect {
      fill: white;
    }
    path {
      stroke: #ccc;
    }
  }
}

// Vertical grid lines
.recharts-cartesian-grid-vertical line {
  stroke: #f8f8f8;
}

// Axis lines
.recharts-xAxis line {
  stroke: #dfdfdf;
}
.recharts-yAxis line {
  stroke: #dfdfdf;
}

// The vertical grid line that follows the cursor
.recharts-tooltip-cursor {
  stroke: #a0c9e0;
}

.custom-recharts-tooltip {
  display: flex;
  flex-direction: row;
  background: #555;
  border-radius: 2px;
  div {
    .font-mono;
    text-align: center;
    color: white;
    font-size: 14px;
    background: #fefefe;
    padding: 2px 8px;
    min-width: 30px;
    font-weight: bold;
    margin: 1px 0.5px;
    &:first-child {
      margin-left: 1px;
      border-radius: 2px 0 0 2px;
    }
    &:last-child {
      margin-right: 1px;
      border-radius: 0 2px 2px 0;
    }
    &.date {
      letter-spacing: -0.4px;
      word-spacing: -4px;
      padding: 2px 10px;
      font-weight: 400;
      color: #555;
    }
  }
}
