.metrics-page {

  .facets {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;

    .dropdown {
      line-height: 26px;
    }

    .brand-location-selectors {
      display: flex;
      flex-direction: row;
    }

    .brand-selector {
      width: 220px;
      margin-right: 10px;
    }
    .location-selector {
      width: 220px;
      margin-right: 40px;
    }

    .date-download {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .download-excel-button {
      margin-left: 20px;
    }

  }

  p.topText {
    margin: 0 0 60px 0;
    font-size: 24px;
    color: #393939;
    font-weight: lighter;
  }

  .section-header {

    h2 {
      white-space: nowrap;
    }

  }


  p {
    margin: 30px 0;
    font-size: 20px;
    color: #525252;
  }

  .metrics-content {
    // ensure content can scroll to center of page before footer is shown
    margin-bottom: 30vh;
  }

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 42px;
    letter-spacing: -0.02em;
    margin-top: 36px;
    color: #393939;
  }

}
