@import (reference) "~/variables.less";

@gray-border: #dcdcdc;
@blue-highlight-light: #fbfcff;
@blue-highlight: #e0edff;
@yellow-highlight: #fff7da;
@yellow-highlight-light: #fffdf4;
@empty-cell: #fcfcfc;
@text-color: #1e1e1e;

.sortable-table {
  border-collapse: collapse;
  border-spacing: 0;
  color: @text-color;

  @media @not-wide-screen {
    width: 100%;
  }
  @media @wide-screen {
    margin: 0 auto;
    width: auto;
    min-width: 1200px;
  }

  th,
  td {
    .font-sans;
    font-style: normal;
    font-weight: normal;
    text-align: right;

    &:first-child {
      font-weight: 600;
      text-align: left;
    }
    &.fixed-width {
      min-width: 110px;
      max-width: 110px;
      width: 110px;
    }
  }

  thead {
    tr {
      th {
        cursor: pointer;
        position: sticky;
        top: 0px;
        background-color: #fff;
        border: 1px solid #fff;
        user-select: none;
        font-size: 13px;
        line-height: 16px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: right;
        vertical-align: top;
        padding: 0;

        &:first-child {
          text-align: left;
        }

        &:hover {
          background-color: @blue-highlight-light;
        }

        .column-label {
          padding: 15px;
          padding-bottom: 30px;

          &::before {
            display: block;
            content: "";
            position: absolute;
            left: -1px;
            right: -1px;
            top: -1px;
            bottom: -1px;
            border: 1px solid @gray-border;
          }
        }

        .sort-arrow {
          position: absolute;
          left: -1px;
          bottom: -1px;
          right: -1px;
          font-size: 9px;
          padding: 0 12px;
          border: 1px solid @gray-border;
          // a little trick to put a dropshadow below the header cells, without
          // spreading left and right
          box-shadow: 0 2px 0 -1px fade(black, 3%),
                      0 3px 0 -1px fade(black, 2%),
                      0 4px 0 -1px fade(black, 1%);

          svg {
            transition: transform 200ms ease-in-out;
            color: #464646;
            opacity: 0;
          }
        }

        &.descending .sort-arrow svg {
          transform: rotateX(0deg);
        }

        &.ascending .sort-arrow svg {
          transform: rotateX(180deg);
        }


        &.active {
          background-color: @blue-highlight-light;

          .sort-arrow {
            background-color: @blue-highlight;

            svg {
              opacity: 1;
            }
          }
        }

        // Share icon with the left column
        &.icon-span-col {
          .column-label {
            &::before {
              border-left: 1px solid #fff;
            }

            svg {
              position: absolute;
              left: -11px;
            }
          }
        }

        // Unfortunately, the borders of other cells show through the header as
        // you scroll down. To prevent this, we can bleed white 1px to the left
        // of borderless cells.
        &.borderless {
          .column-label::before {
            border-left: 1px solid #fff;
          }
        }
        // But when a borderless cell is preceded by a non-borderless cell, the
        // left border bleed needs to be gray.
        &:not(.borderless) + .borderless {
          .column-label::before {
            border-left: 1px solid @gray-border;
          }
        }
      }
    }
  }

  tbody {
    tr {
      &:first-child td > * {
        padding-top: 32px;
      }

      &:hover td {
        background-color: @yellow-highlight-light !important;
      }

      td {
        border: 1px solid @gray-border;
        background-color: #fff;

        &:first-child {
          .font-sans;
          cursor: pointer;
        }

        &:empty {
          background-color: @empty-cell;
        }

        &:hover {
          background-color: @yellow-highlight !important;
        }

        .font-mono;
        font-style: normal;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: -0.02em;
        white-space: nowrap;

        > * {
          display: block;
          color: @text-color;
          padding: 16px 12px 12px;
        }
      }
    }
  }
}
