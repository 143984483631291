.dropdown {
  @base-color: #dbecfc; // same as @active-button-color

  @rest-background-color: #ffffff;
  @hover-background-color: fade(@base-color, 25%);
  @active-background-color: fade(@base-color, 50%);
  @rest-text-color: #666;
  @active-text-color: #333;

  @border-color: #b2cae2;
  @divider-color: lighten(@border-color, 10%);
  @active-bottom-border-color: lighten(@border-color, 15%);

  @rest-arrow-color: lighten(@rest-text-color, 20%);
  @active-arrow-color: lighten(@active-text-color, 20%);

  @selected-item-color: #dbecfc;
  @selected-item-text-color: #2186eb;


  display: block;
  outline: 0;
  transform: rotateZ(0deg);
  color: @rest-text-color;
  background-color: @rest-background-color;
  padding: 1px;
  box-shadow: 1px 1px 4px fade(black, 10%);
  border: 1px solid @border-color;
  border-radius: 2px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  position: relative;

  &.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 1;

    &::after {
      display: block;
      content: "";
      width: 100%;
      height: 1px;
      background-color: @active-bottom-border-color;
      position: absolute;
      left: 0;
      top: 100%;
    }
  }
  &.fluid {
    width: 100%;
  }

  // first .text node is the rest state. apply drowdown arrow here
  > .text {
    display: block;
    padding: 8px 1.5em;
    padding-right: calc(1.5em + 20px);
    position: relative;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &::after {
      .triangle(6px);
      border-top-color: @rest-arrow-color;
    }
  }
  &:hover > .text {
    background-color: @hover-background-color;
  }
  &.active > .text {
    background-color: @rest-background-color;
    color: @active-text-color;
    &::after {
      border-top-color: @active-arrow-color;
    }
  }
  &.fluid > .text {
    width: 100%;
  }

  // .ui icon we don't want
  i.dropdown.icon {
    display: none;
  }

  .menu {
    display: none;
  }

  &.active .menu {
    display: block;
    position: absolute;
    left: -1px;
    top: 100%;
    border: 1px solid @border-color;
    border-radius: 3px;
    border-top-left-radius: 0;
    box-shadow: 1px 1px 4px fade(black, 10%);
    background-color: @rest-background-color;
    padding: 1px;
    padding-top: 5px;
    padding-bottom: 8px;
    user-select: none;
    min-width: calc(100% + 20px);
    max-height: 50vh;
    overflow-y: scroll;
  }

  // Hide Semantic UI search input because we have our own
  .search {
    display: none;
  }

  .input-search {
    appearance: none;
    outline: none;
    border: none;
    border-radius: 2px;
    line-height: 19px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1;
    width: 100%;
  }

  .divider {
    border-top: 1px solid @divider-color;
    height: 0em;
    margin: 8px 0;
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 9px 1.5em;
    cursor: pointer;

    &:hover {
      background-color: @hover-background-color;
      color: #333;
    }

    .text {
      padding: 0;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .with-checkbox {
      white-space: nowrap;
      svg {
        width: 16px;
        height: 16px;
        margin: -3px; // so it doesn't increase line-height
        margin-right: 12px;
        stroke-width: 2;
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

  }
  .item + .item {
    margin-top: 1px;
  }

  a.item {
    color: inherit;
    text-decoration: inherit;
  }

}

// Less mixin for creating the triangle on top of the response bubble
.triangle(@size) {
  width: 0;
  height: 0;
  border-left: @size solid transparent;
  border-right: @size solid transparent;
  border-top: @size solid;

  display: block;
  content: "";
  position: absolute;
  top: calc(50% - @size / 2);
  right: 15px;
}
