.nps-badge {
  display: inline-block;
  border-radius: 3px;
  border: 1px solid;
  text-align: center;

  .nps-score {
    text-align: center;
    font-size: 24px;
    line-height: 33px;
    font-weight: 600;
    border-bottom: 1px solid;
    border-color: inherit;
    width: 1.4em;
    letter-spacing: -0.115em;
    // for some reason the negative letter-spacing causes the text to appear
    // not-quite centered. a bit of padding on the right corrects this.
    padding-right: 0.1em;
  }

  .nps-title {
    font-size: 11px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 0.07em;
    color: #afafaf;
  }

  &.score-0 {
    color: #E06464;
    border-color: #EB907D;
    .nps-score {
      background-color: #FFD1D1;
    }
  }
  &.score-1,
  &.score-2,
  &.score-3,
  &.score-4,
  &.score-5,
  &.score-6 {
    color: #E06464;
    border-color: #FFC1B4;
    .nps-score {
      background-color: #FFEEEE;
    }
  }
  &.score-7,
  &.score-8 {
    color: #9E7D18;
    border-color: #F2C94C;
    .nps-score {
      background-color: #FFE485;
    }
  }
  &.score-9 {
    color: #0E4E29;
    border-color: #6FDC9D;
    .nps-score {
      background-color: #C6ECD6;
    }
  }
  &.score-10 {
    color: #0E4E29;
    border-color: #27DB73;
    .nps-score {
      background-color: #93F2BB;
      padding-right: 0.15em;
    }
  }

}
