.fullscreen-message {
  min-height: 75vh;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  svg {
    width: 300px;
    margin-bottom: 40px;
  }

  .error-message {
    display: inline-block;
    padding: 30px;
    background-color: fade(red, 5%);
    border: 1px solid fade(black, 20%);
    border-radius: 3px;
    box-shadow: 2px 2px 4px fade(black, 5%);
  }

  h1.error-title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }

  p {
    &:last-child {
      margin-bottom: 3px;
    }
  }
}
