@import (reference) '~/variables.less';

.lead-conversion {
  h2 {
    .font-sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  p.top-text {
    .font-sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 30px
  }

  p.bottom-text {
    color: #AAAAAA;
    .font-sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  }

  p.empty-metric {
    text-align: center;
    color: #AAAAAA;
    padding: 30px 0;
  }

  .flow-chart {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    padding: 10px;
  }

  .metrics-box {
    width: 275px;
    overflow: hidden;

    .top {
      position: relative;
      padding: 25px;
      box-sizing: border-box;
      border-radius: 4px 4px 0 0;

      &:only-child {
        border-radius: 4px;
      }

      // Indents for the metrics boxes
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        background-color: #fff;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        top: 0;
        left: 0;
        transform: translate(-39px, 48px);
      }

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        background-color: #fff;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        top: 0;
        right: 0;
        transform: translate(39px, 48px);
      }

      &.green-1 {
        background: #DBFFEA;
        border: 1px solid #60C68B;
        border-bottom: none;

        &::before,
        &::after,
        &:only-child {
          border: 1px solid #60C68B;
        }
      }

      &.green-2 {
        background: #A9FFCD;
        border: 1px solid #34D177;
        border-bottom: none;

        &::before,
        &::after,
        &:only-child {
          border: 1px solid #34D177;
        }
      }

      &.green-3 {
        background: #73FFAD;
        border: 1px solid #50B179;
        border-bottom: none;

        &::before,
        &::after,
        &:only-child {
          border: 1px solid #50B179;
        }
      }

      h2 {
        .font-mono;
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        text-align: center;
        margin: 0 0 15px 0;
        color: #146335;
      }

      p {
        .font-sans;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        margin: 0;
        color: #146335;
      }
    }


    // Remove the outter indents from the first and last elements
    &:first-child .top::before {
      display: none;
    }

    &:last-child .top::after {
      display: none;
    }

    ;

    .list {
      background: #FFFFFF;
      border: 1px solid #D3D3D3;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      border-radius: 0px 0px 4px 4px;

      li {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 13px 20px;
        margin: 0;
        border-top: 1px solid #E9E9E9;

        * {
          margin: 0;
          padding: 0;
        }

        .item-title {
          .font-sans;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 25px;
          display: flex;
          align-items: center;
          color: #565656;

          svg {
            width: 16px;
            margin-right: 13px;
            stroke: 1px #9B9B9B solid;
          }
        }

        .item-value {
          .font-mono;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 28px;
          display: flex;
          align-items: center;
          text-align: right;
          color: #757575;
        }
      }
    }
  }

  .conversion {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    position: relative;
    flex-grow: 2;
    padding: 10px;

    .conversion-rate {
      .font-mono;
      display: block;
      color: #8B8B8B;
      background: #EEEEEE;
      border: 1px solid #DADADA;
      box-sizing: border-box;
      border-radius: 2px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      padding: 8px 16px;
      margin: 40px 0 0 0;
    }

    // Arrows under conversion
    &::before {
      position: absolute;
      display: block;
      content: '';
      width: 90%;
      height: 10px;
      border-bottom: 3px solid #dadada;
      top: 64px;
      left: 5%;
      z-index: -1;
    }

    &::after {
      position: absolute;
      display: block;
      content: '';
      width: 15px;
      height: 15px;
      border-top: 3px solid #dadada;
      border-right: 3px solid #dadada;
      transform: rotate(45deg);
      top: 65px;
      right: 5.5%;
    }
  }
}