.star-rating {

  display: inline-flex;
  flex-direction: row;

  .service {
    width: 24px;
    margin-right: 10px;
  }

  .stars {
    display: flex;
    flex-direction: row;
  }

  .star svg {
    stroke-width: 1px;
  }

  .star.solid svg {
    fill: #F2C94C;
    stroke: #C8A94B;
  }
  .star.outline svg {
    fill: #FFF6DC;
    stroke: #C8A94B;
  }

}
