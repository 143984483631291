@import (reference) '~/variables.less';

.loading-shimmer {
  @gradient-low: fade(@broadly-blue, 2%);
  @dot-color: fade(@broadly-blue, 20%);

  overflow: hidden;
  position: relative;
  min-height: 50vh;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @gradient-low;

  .dots {
    padding-top: 20px;
    padding-bottom: 40px;

    .dot {
      width: 18px;
      height: 18px;
      margin: 3px;
      background-color: @dot-color;
      border-radius: 100%;
      display: inline-block;
      animation: dotScaling 1.4s infinite ease-in-out both;
    }

    .dot:nth-child(1) {
      animation-delay: -0.32s;
    }

    .dot:nth-child(2) {
      animation-delay: -0.16s;
    }

  }

}

@keyframes dotScaling {
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
}
