@import (reference) "~/variables.less";

.text-analytics {
  padding-top: 60px;
  width: 250px;
  margin-left: 48px;
  position: relative;

  button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;

    &:focus {
      outline: none;
    }

    &:hover {
      opacity: .8;
    }
  }

  .terms-header {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.2em;
    color: #808080;
    text-align: center;
    margin-bottom: 20px;
    padding-right: 1em; // to offset the icon, for optical centering of text

    svg {
      margin-right: 10px;
      width: 1.2em;
      height: 1.2em;
      vertical-align: baseline;
      transform: translateY(1.5px);
    }
  }

  .terms-box {
    background-color: #fff;
    border: 1px solid fade(black, 10%);
    border-radius: 3px;
    padding: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    overflow: hidden;
  }

  .terms-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .terms-legend {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6px 22px;
    background-color: fade(black, 8%);
    margin: -12px -8px;
    margin-bottom: 12px;

    .filter-by {
      font-size: 10px;
      text-transform: uppercase;
      color: fade(black, 40%);
      letter-spacing: 0.1em;
      padding: 2px 0;
    }
  }

  .term-item {
    margin: 0;
    padding: 12px;
    transition: all .3s ease;
    line-height: 1;
    border: 1px solid transparent;
    border-radius: 2px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    &.active {
      background: #F7FBFF;
      border: 1px solid #A3C3E2;
      box-sizing: border-box;
      box-shadow: inset 0px 0px 10px rgba(183, 210, 237, 0.5);
      cursor: pointer;
    }

    &:hover:not(.active) {
      cursor: pointer;
      background: #F9F9F9;
      border: 1px solid #F2F2F2;
    }

    .term-and-total {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 16px;
      padding: 0 1px;
    }

    .term {
      color: #636363;
      font-size: 16px;
      font-family: 'Open Sans', sans-serif;

      &.active {
        font-weight: 600;
        color: #2186EB;
      }
    }

    .total {
      .font-mono;
      font-feature-settings: "pnum" on, "onum" on;
      color: #b4b4b4;
    }

    .percent-chart {
      display: flex;
      flex-direction: row;
      justify-content: stretch;

      .percent-bar {
        margin-top: 5px;
        height: 8px;
        border: 1px solid rgba(0,0,0,.1);

        &:hover {
          cursor: pointer;
          opacity: .8;
        }

        &.active {
          border: 1px solid rgba(0, 0, 0, 0.1);
        }

        &.promoter {
          background-color: #c6ecd6;
        }
        &.passive {
          background-color: #ffe485;
        }
        &.detractor {
          background-color: #ffd1d1;
        }
      }
    }
  }

  .no-results {
    text-align: center;
    font-size: 13px;
    color: #999;
    padding: 20px;
  }
}
