.brands-page {
    margin-bottom: 30vh;

    .facets {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 40px 0;
      margin-bottom: 40px;

      .dropdown {
        line-height: 26px;
      }

      .brand-location-selectors {
        display: flex;
        flex-direction: row;
      }

      .brand-selector {
        width: 220px;
        margin-right: 10px;
      }
      .location-selector {
        width: 220px;
        margin-right: 40px;
      }

      .date-download {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .download-excel-button {
        margin-left: 20px;
      }
    }

    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 38px;
      line-height: 42px;
      letter-spacing: -0.02em;

      color: #393939;
    }

    .sortable-table {

      th {
        &.brand-icon {

          .column-label {
            text-align: center;
            svg {
              height: 22px;
            }
          }
        }
        &.google .column-label svg {
          color: #F2C94C;
        }

        &.facebook .column-label svg {
          color: #2F80ED;
        }

        &.yelp .column-label svg {
          color: #D32323;
        }

        &.nextdoor .column-label svg {
          color: #7DC49B;
        }
      }

      .rating span {
        svg {
          width: 15px;
          stroke-width: 1px;
          fill: #F2C94C;
          stroke: #C8A94B;
          margin-right: 5px;
          padding-top: 10px;
        }
      }
    }

  }
