.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0px;
  text-align: center;
  z-index: 1000;
  transform: translateX(-50%) translateY(-50%);

  /* Static Shape */
  &:before {
    position: absolute;
    content: "";
    top: 0%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 500rem;
    border: 0.2em solid rgba(0, 0, 0, 0.1);
    transform: translateX(-50%) translateY(-100%);
  }

  /* Active Shape */
  &:after {
    position: absolute;
    content: "";
    top: 0%;
    left: 50%;
    width: 100%;
    height: 100%;
    animation: loader 0.6s linear;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #767676 transparent transparent;
    border-style: solid;
    border-width: 0.2em;
    box-shadow: 0px 0px 0px 1px transparent;
  }
}

@keyframes loader {
  from {
    transform: translateX(-50%) translateY(-100%) rotate(0deg);
  }

  to {
    transform: translateX(-50%) translateY(-100%) rotate(360deg);
  }
}
