@broadly-blue: #094a79;

.font-sans {
  font-family: 'Open Sans', sans-serif;
}

.font-mono {
  font-family: 'Nova Mono', monospace;
}

@block-content-width: 1200px;
@block-padding: 20px;
@block-threshold: @block-content-width + (@block-padding * 2);

@wide-screen: ~"only screen and (min-width: @{block-threshold})";
@not-wide-screen: ~"only screen and (max-width: @{block-threshold})";

// this value is derived from Responsive.onlyTablet.minWidth
// this applies to tablet or smaller
@tablet: ~"only screen and (max-width: 768px)";
// anything larger than a tablet
@not-tablet: ~"only screen and (min-width: 768px)";
