.account-page {
  .facets {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    margin-bottom: 10px;

    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 38px;
      line-height: 42px;
      letter-spacing: -0.02em;
      color: #393939;
      margin: 0;
    }

    .copy {
      font-size: 20px;
      color: #525252;
      margin: 0;
      margin-top: 13px;
    }

    .button.add-team-member {
      margin-left: auto;
      @button-color: #4ED073;
      background-color: @button-color;
      color: #fff;
      &:hover {
        background-color: darken(@button-color, 10%);
      }
    }
  }

  .users-list {
    border-radius: 4px 4px 0px 0px;
    box-sizing: border-box;

    .user-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 15px 23px;
      border: 1px solid #D1D1D1;
      border-bottom: 0;
      border-radius: 4px 4px 0px 0px;

      &:first-child {
        border-radius: 4px 4px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 0px 4px 4px;
        border-bottom: 1px solid #D1D1D1;
      }

      .user {
        display: flex;
        flex-direction: column;

        .name {
          font-size: 20px;

          .current-user {
            color: #B4B4B4;
            margin-left: 5px;
          }
        }

        .user-contact-details {
          display: flex;
          flex-direction: row;
          color: #B4B4B4;
          font-size: 16px;

          .phone {
            margin-left: 20px;
          }
        }
      }

      .actions {
        cursor: pointer;
        margin-left: auto;
        font-weight: 600;
        font-size: 14px;
        background: #BABABA;
        border: 1px solid #A0A0A0;
        box-sizing: border-box;
        border-radius: 18px;
        color: white;
        font-size: 12px;
        line-height: 19px;
        padding: 2px 9px;

        a {
          color: white;
          text-decoration: none;
        }

        &:hover {
          opacity: .8;
        }
      }
    }
  }

}
