.metrics-section {
  .head {
    margin-bottom: 24px;

    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      letter-spacing: -0.02em;
      color: #393939;
      margin: 0;
    }

    h2 {
      font-style: normal;
      font-weight: 300;
      font-size: 24px;
      line-height: 1.3;
      letter-spacing: -0.02em;
      color: #393939;
      margin: 0;
    }
  }

  & + & {
    margin-top: 50px;
  }

}
