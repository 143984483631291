@import (reference) "~/variables.less";

.metrics-reviews {
  @gutter: 24px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .service {
    .section-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0px;

      svg {
        width: 48px;
        margin-right: 14px;
        display: block;
      }

      h1 {
        font-size: 24px;
        font-weight: 600;
        margin: 0;
        line-height: 1.1;
      }

      h2 {
        font-size: 24px;
        font-weight: 100;
        margin: 0;
        line-height: 1.1;
      }
    }

    .charts {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // if two charts, two columns
      > * {
        width: calc(50% - (@gutter / 2));
      }
      // if first child is last child, that's one column
      > :first-child:last-child {
        width: 100%;
      }
    }

    .current-star-rating {
      svg {
        fill: #f2c94c;
        stroke: #c8a94b;
        margin-right: 5px;
      }
    }
  }

  .service + .service {
    margin-top: 50px;
  }

  @media @not-tablet {
    .service {
      &.has-ratings {
        width: 100%;
      }
      &:not(.has-ratings) {
        width: calc(50% - (@gutter / 2));
      }
    }
  }

  @media @tablet {
    .service {
      width: 100%;

      .charts {
        flex-direction: column;

        .visualize-metrics {
          width: 100%;
          margin-bottom: @gutter;
        }
      }
    }
  }
}
