.team-page {
  margin-bottom: 30vh;

  .facets {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;

    .dropdown {
      line-height: 26px;
    }

    .brand-location-selectors {
      display: flex;
      flex-direction: row;
    }

    .brand-selector {
      width: 220px;
      margin-right: 10px;
    }
    .location-selector {
      width: 220px;
      margin-right: 40px;
    }

    .date-download {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .download-excel-button {
      margin-left: 20px;
    }
  }

  .team-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    .checkbox {
      background-color: #fafafa;

      label {
        display: block;
        padding: 6px 10px;
        border-radius: 4px;
        font-size: 15px;
        letter-spacing: -.02px;
        font-weight: 400;
        color: #555;

        &:hover {
          cursor: pointer;
          background-color: #f7f7f7;
          color: #333;

          input[type="checkbox"] {
            border-color: #aaa;
          }

          input[type="checkbox"]:checked {
            border-color: #999;
          }
        }

        input[type="checkbox"] {
          appearance: none;
          -webkit-appearance: none;
          display: inline-block;
          cursor: pointer;
          user-select: none;
          border-radius: 2px;
          border: 1px solid #ddd;
          background-color: #fff;
          text-align: center;
          vertical-align: middle;
          width: 20px;
          height: 20px;
          margin: 0;
          margin-top: -3px;
          margin-right: 5px;
          outline: none;

          &:checked {
            background-color: #fff;
            border-color: #bbb;
          }

          &:checked {
            position: relative;
            white-space: nowrap;
            &:before {
              content: "";
              display: block;
              border-left: 3px solid #656565;
              border-bottom: 3px solid #656565;
              right: 2px;
              top: 4px;
              width: 14px;
              height: 7px;
              position: absolute;
              transform: rotate(-52deg);
            }
          }
        }
      }
    }
  }

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 42px;
    letter-spacing: -0.02em;

    color: #393939;

    margin-top: 1.5em;
    margin-bottom: 1em;
  }

  td svg {
    width: 12px;
    height: 12px;
    color: #2f80ed;
    vertical-align: text-top;
  }

  .copy {
    font-size: 20px;
    color: #525252;
    margin: 0.5em 0;
  }

  .truncated-cell a {
    max-width: 400px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
