@import (reference) '~/variables.less';

// GENERAL

body {
  .font-sans;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 1.5;
}

a {
  color: #4183C4;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

// top-level app layout
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  // force main section of app layout to fill height, so footer always hugs the
  // bottom of the viewport when content is short.
  > main {
    flex-grow: 1;
  }
}

// use box-sizing: border-box everywhere
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}


/* Why is this not handled by the browser? */
details summary:hover {
  cursor: pointer;
}

details summary {
  outline: none;
}

// TEXT

h1, h2, h3, h4, h5, h6 {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 10px;
}

p {
  margin: 0.75em 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

// FORMS

input {
  font-family: inherit;
}
