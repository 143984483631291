@import './spinner.less';

.block.fullscreen-spinner {
  min-height: 100vh;
  justify-content: center;
  .loader {
    // optically center the text since we have ellipsis punctuation on the right
    padding-left: 0.5em;
    font-size: 18px;
    color: #666;
    padding-top: 5px;

    &:before,
    &:after {
      width: 40px;
      height: 40px;
    }
  }
}
